<template>
  <div lane-drop-input>
    <svg-lol-lane :value="value" />
    <input :value="text" :placeholder="placeholder" :type="type" :readonly="readonly" disabled />
  </div>
</template>

<script>
import SvgLolLane from '@/views/components/coaching/SvgLolLane.vue';

export default {
  components: { SvgLolLane },
  props: ['value', 'placeholder', 'type', 'readonly', 'text'],
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[lane-drop-input] {.flex;.mt(8);.ml(8);
  > input { width: auto;flex: 1;.mt(6)}
  [svg-lol-lane] {.fill(#787781);.wh(20, 20);.mt(4)}
}
</style>
