<template>
  <label switch-nickname-private>
    <span>{{ $t('_.MS_writecard_nickname') }}</span>
    <simple-tabs :value="value" @input="v => $emit('input',v)">
      <simple-tab :key="true" tag="button">{{ $t('_.BT_close') }}</simple-tab>
      <simple-tab :key="false" tag="button">{{ $t('_.BT_open') }}</simple-tab>
    </simple-tabs>
  </label>
</template>

<script>
import SimpleTabs from '@/views/components/coaching/SimpleTabs.vue';
import SimpleTab from '@/views/components/coaching/SimpleTab.vue';

export default {
  components: { SimpleTabs, SimpleTab },
  lexicon: 'coaching',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[switch-nickname-private] {.flex;.flex-ai(center);
  > span {.mr(10);.c(#a4a3ae);.fs(14)}
  [simple-tabs] {.wh(136, 36);.br(12);.bgc(#ebebf0);}
  [simple-tab] {.wh(68, 36);}
  [simple-tab]:not(.active) {.c(#787781)}
  [simple-tab].active {.c(#fff);.bgc(#191919);.br(12);}
}
</style>
