<template>
  <Modal modal-post-question data-cy="post-question-modal" @close="$emit('close')">
    <template #title>
      <h1 class="top-title">코치님들께 질문 있습니다.</h1>
    </template>
    <validation-observer ref="validator">
      <h2 class="champ-title" v-if="preferChamps.length >= 1"><em>{{ userNickname }}</em>
        <span>님의 선호 챔피언</span>
      </h2>
      <prefer-champ-radio-group v-model="champCode" v-if="preferChamps.length >= 1">
        <prefer-champ-radio v-for="preferChamp in preferChamps" :prefer-champ="preferChamp" :key="preferChamp.champion.code" />
      </prefer-champ-radio-group>
      <div class="position-champ-filters">
        <label class="position">
          <span>포지션</span>
          <lane-drop-select :list="lolLaneList" v-model="lolLane" />
        </label>
        <label class="champ">
          <span>질문할 챔피언</span>
          <input-suggest :suggest="onSuggestChamp" v-model="champCode" :text.sync="champText" :get-item-prop="c => ({ ...c, key: c.code, checked: c.code === champCode })" :item-tag="ChampSelectItem" :on-select="onChampSelect">
            <template #no-suggestion>해당하는 챔프가 없습니다</template>
          </input-suggest>
        </label>
      </div>
      <validation-provider rules="required|lengthMax:36" name="questionTitle" v-slot="{errors}">
        <text-input class="question-title" v-model="title" placeholder="제목입력(최대 36자 이내)" />
        <validation-errors :errors="errors" />
      </validation-provider>
      <validation-provider rules="required|lengthMin:10|lengthMax:400" name="questionContent" v-slot="{errors}" v-model="content">
        <text-box class="question-content" v-model="content" placeholder="내용 입력(최소 10자, 최대 400자 이내)" />
        <validation-errors :errors="errors" />
      </validation-provider>
      <div class="bottom-control">
        <question-image-selector v-model="imageUrls" />
        <switch-nickname-private v-model="usePrivateName" />
      </div>
      <div class="area-submit">
        <primary-button @click="onSubmit">발송</primary-button>
      </div>
    </validation-observer>
  </Modal>
</template>
<script>
import Modal from '@shared/components/common/Modal.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import PreferChampRadio from '@/views/components/coaching/PreferChampRadio.vue';
import PreferChampRadioGroup from '@/views/components/coaching/PreferChampRadioGroup.vue';
import InputSuggest from '@/views/components/coaching/InputSuggest.vue';
import ChampSelectItem from './ChampSelectItem.vue';
import TextBox from '@/views/components/common/input/TextBox.vue';
import LaneInput from './LaneDropInput.vue';
import QuestionImageSelector from '@/views/components/coaching/post-question/QuestionImageSelector.vue';
import SwitchNicknamePrivate from '@/views/components/coaching/post-question/SwitchNicknamePrivate.vue';
import LaneDropSelect from '@/views/components/coaching/post-question/LaneDropSelect.vue';

export default {
  name: 'ModalPostQuestion',
  components: { LaneDropSelect, SwitchNicknamePrivate, QuestionImageSelector, PrimaryButton, TextBox, TextInput, InputSuggest, PreferChampRadioGroup, Modal, PreferChampRadio },
  props: {
    options: {
      type: Object,
      default: () => ({
        coachId: '',
        userId: '',
        title: '',
        content: '',
        lolLane: 'ALL',
        imageUrls: [],
        usePrivateName: false,
        userNickname: '',
      }),
    },
  },
  data: () => ({
    preferChamps: /** @type{PreferChamp[]} */[],
    champs: /** @type{LolChamp[]} */[],
    champCode: '',
    champText: '',
    title: '',
    content: '',
    lolLanes: /** @type {QuestionCategoryLane[]} */[],
    lolLane: 'ALL',
    imageUrls: [],
    usePrivateName: true,
    userNickname: '',
  }),
  computed: {
    ChampSelectItem: () => ChampSelectItem,
    LaneInput: () => LaneInput,
    currentChamp() {
      return /** @type{LolChamp} */ this.champs.find(c => c.code === this.champCode) || {};
    },
    lolLaneList() {
      return this.lolLanes.map(l => ({ label: l.text, value: l.value }));
    },
    categories() {
      const categories = [];
      if (this.champCode !== '') categories.push({ groupName: 'LOL_CHAMPION', value: this.champCode });
      if (this.lolLane !== '') categories.push({ groupName: 'LOL_LANE', value: this.lolLane });
      return categories;
    },
    result() {
      const result = { title: this.title, content: this.content, usePrivateName: this.usePrivateName };
      if ((this.imageUrls?.length || 0) >= 1) result.imageUrls = this.imageUrls;
      result.categories = this.categories;
      return result;
    },
  },
  watch: {
    champCode() {
      if (this.champText !== this.currentChamp.koName) {
        this.champText = this.currentChamp.koName;
      }
    },
  },
  methods: {
    async onSuggestChamp(keyword) {
      const re = new RegExp(keyword, 'i');
      return this.champs.filter(c => re.test(c.koName) || re.test(c.enName));
    },
    onChampSelect({ value, setValue, setText }) {
      setValue(value?.code);
      setText(value?.koName);
    },
    async onSubmit() {
      await this.$validate(this.$refs.validator);
      this.$emit('resolve', this.result);
    },
  },
  async beforeMount() {
    const { userId, title = '', content = '', lolLane = 'ALL', imageUrls = [], usePrivateName = true, userNickname = '' } = this.options;
    this.preferChamps = await this.$services.coaching.getPreferChamp({ userId });
    this.champs = await this.$services.coaching.getLolChamps();
    this.lolLanes = await this.$services.coaching.getCategories('LOL_LANE');
    this.title = title;
    this.content = content;
    this.lolLane = lolLane;
    this.imageUrls = imageUrls;
    this.usePrivateName = usePrivateName;
    this.userNickname = userNickname;
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[modal-post-question] {.noto;
  .panel.large {.w(100%);.max-w(720);}
  .top-title { .fs(20);.lh(28);.c(@c-title-black);.pb(20);border-bottom: solid 1px #d5d5de;.mb(20)}
  .champ-title {.fs(14);.lh(21);.bold;.mb(7);
    span {.c(@c-title-black)}
    em {.c(#2c81ff)}
  }
  .position-champ-filters {.mt(20);
    label.position > span, label.champ > span {.mb(8);.c(#191919);.fs(14);.lh(14);.bold;.block;}
    label.champ {flex-grow: 1;}
    [input-suggest] input[type='text'] {.h(40)}
    label.champ > [input-suggest].list-visible {
      input[type='text'] { border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
    }
    [input-suggest]:after {content: '';.abs;.bg('@{img}/icon/ico_search_dark.svg');.bg-c();.wh(18, 18);.z(3);.rt(10, 10);}
    [input-suggest] .suggest-list {.p(8);.t(40);.bgc(#ebebf0);border-bottom-left-radius: 12px;border-bottom-right-radius: 12px;.max-h(300);.scroll;
      border-top: solid 1px #d5d5de;
    }
  }
  [prefer-champ-radio-group] {.flex-gap(10px)}
  .position, .champ > span {.block;}
  .question-title {.mt(43)}
  .question-content {.mt(32);.h(280);}
  .bottom-control { .mt(42) }
  .area-submit {.mt(49);.mb(48);text-align: right;
    [primary-button] {.wh(240, 56);.br(28);}
  }
  [validation-errors] {.c(#ffc70e);.fs(12);.lh(12);text-align: right;.mt(4);}
  @media (@tp-up) {
    .position-champ-filters {.flex;
      > .position {.mr(20);.w(200)}
    }
    .bottom-control {.flex;
      [question-image-selector] {flex-grow: 1;}
    }

  }
  @media (@tp-down) {
    .position-champ-filters > .position {.mb(20)}
    [question-image-selector] {.mb(20)}
  }
}
</style>
