<template>
  <div drop-select ref="dropSelect" :class="[`no-drag ${direction}`, { focus, error: error && !Boolean(dropListBox), readonly, disabled, open: Boolean(dropListBox), yet: !value, }, theme]">
    <label  @click="toggleOpen">
      <LaneDropInput :value="value" type="text" :placeholder="placeholder" readonly="readonly" :text="selected && ( selected.label || selected )" />
    </label>
    <input :name="name" type="hidden" :value="value"/>
    <p class="angle">
      <SvgAngle/>
    </p>
  </div>
</template>

<script>
import DropSelect from '@/views/components/common/input/DropSelect.vue';
import LaneDropListBox from '@/views/components/coaching/post-question/LaneDropListBox.vue';
import Vue from 'vue';
import LaneDropInput from '@/views/components/coaching/post-question/LaneDropInput.vue';

export default {
  components: { LaneDropInput },
  extends: DropSelect,
  props: {
    tag: {
      type: [String,Object],
      default: 'input'
    },
  },
  methods: {
    // DropSelect.vue 의 createDropList에서 컴포넌트만 바꾼 것
    createDropList() {
      if(this.readonly) return;
      this.removeDropList();
      this.dropListBox = new (Vue.extend(LaneDropListBox))({
            parent:this.$root,
            propsData: {
              callComponent: this,
              className: this.variant,
              list: this.listCreated,
              value: this.value,
              direction: this.direction,
              theme: this.theme,
            }
          }
      ).$mount();

      this.focus = true;
    },
    eventListenerHandler(destroy = false){
      if(destroy){
        document.body.removeEventListener('click', this.clickOutSideHandler);
        window.removeEventListener('resize', this.removeDropList);
        // TODO: ModalPostQuestion에서 해당 코드 추가 이후 동작하지 않는 이슈로 인하여 임시 주석처리
        // document.removeEventListener('scroll', this.removeDropList, true);
      }else{
        document.body.addEventListener('click', this.clickOutSideHandler);
        window.addEventListener('resize', this.removeDropList);
        // $document.addEventListener('scroll', this.removeDropList, true);
      }
    }
  },

  mounted() {
    this.eventListenerHandler();
    this.update(true);
  },
  destroyed() {
    this.removeDropList();
    this.eventListenerHandler(true);
  },
}
</script>
