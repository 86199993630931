<template>
  <div drop-list :class="[convertDirection, className, {mounted, animated, 'on': showList},theme]" :style="elStyle">
    <ul ref="listBox" class="list-box">
      <li v-for="(item, index) in (showList || [])" :key="`list-${index}${uid}`"
        :class="{'active': value === (item.value || item)}" @mousedown.left="clickListHandler(item)" :value="item.value">
        <svg-lol-lane :value="item.value" />
        <span>{{ item.label || item }}</span>
      </li>
      <li v-show="!showList.length" class="none-data">{{ $t('input.noList') }}</li>
    </ul>
  </div>
</template>

<script>
import DropListBox from '@/views/components/common/input/DropListBox.vue';
import SvgLolLane from '@/views/components/coaching/SvgLolLane.vue';

export default {
  extends: DropListBox,
  components: {
    SvgLolLane,
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[drop-list] {.noto;
  .list-box > li:not(.none-data) {.flex;.flex-ai(center);
    [svg-lol-lane] {.fill(#787781);.wh(20, 20);.mr(8);}
  }
}
</style>
