<template>
  <div question-image-selector>
    <label v-show="noImage" class="button-upload">
      <color-button @click="onUpload" type="light-gray">
        <svg-camera-outline />
        {{ $t('_.BA_writecard_upload_image1') }}
      </color-button>
      <p class="upload-description">
        {{ $t('_.BA_writecard_upload_image2', { highNumber: maxAmount }) }} {{ $t('_.BA_writecard_upload_image3', { highNumber: maxMB }) }}
      </p>
    </label>
    <div class="previews">
      <cdn-img v-for="(src, idx) in value" :key="idx" class="preview-image" :src="src" d="200x80" />
      <color-button class="button-remove" @click="onResetImages" type="light-gray" v-show="hasImage">
        <svg-trash />
      </color-button>
    </div>
    <input type="file" accept="image/jpeg,image/png" ref="fileInput" @change="onFileSelected" multiple />
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgTrash from '@shared/graphics/svg-trash.vue';
import SvgCameraOutline from '@/views/graphics/svg-camera-outline.vue';

export default {
  components: { SvgCameraOutline, SvgTrash, ColorButton, CdnImg },
  lexicon: 'coaching',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    maxAmount: {
      type: Number,
      default: 3,
    },
    maxMB: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    noImage() {
      return this.value?.length === 0;
    },
    hasImage() {
      return this.value?.length >= 1;
    },
  },
  methods: {
    onUpload() {
      this.$refs.fileInput.click();
    },
    async onFileSelected(ev) {
      try {
        const files = Array.from(ev?.target?.files);
        if (files?.length > this.maxAmount) throw new Error('FILE_SELECT_FILE_AMOUNT_EXCEED');
        const fileSizes = files.map(f => f.size || 0);
        const targetSize = 1024 * 1024 * this.maxMB;
        if (fileSizes.some(fs => fs > targetSize)) throw new Error('최대 1mb 의 이미지만 올릴 수 있습니다');
        await this.uploadFiles(files);
      } catch (err) {
        this.$toast(`_.${err}`);
      }
    },
    async uploadFiles(fileData) {
      const srcs = [];
      for (const file of fileData) {
        const src = await this.$services.aws.upload(file);
        srcs.push(src?.key);
      }
      this.$emit('input', srcs);
    },
    onResetImages() {
      this.$emit('input', []);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[question-image-selector] {
  > input[type='file'] {display: none;}
  .previews {.flex;.flex-ai(center);
    > * + * {.ml(8);}
  }
  .preview-image { .wh(117, 40);.br(12);border: 1px dashed #a4a3ae;}
  .button-upload {.flex;.flex-ai(center);}
  .button-upload [color-button] {.h(40);.br(12);border: 1px dashed #a4a3ae;.c(#787781);.mr(12);.flex;.flex-ai(center);}
  .upload-description { .fs(12);.c(#a4a3ae);.lh(14.4);.w(150)}
  [svg-camera-outline] { stroke: #fff;.wh(24, 24);}
;
}
</style>
