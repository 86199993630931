<template>
  <li @click="$emit('select', { koName, code })" champ-select-item role="checkbox" :aria-checked="checked">
    {{ koName }}
  </li>
</template>

<script>
export default {
  props: {
    championId: {
      type: String,
      default: '',
    },
    koName: {
      type: String,
      default: '',
    },
    enName: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[champ-select-item] {.c(#191919);.h(40);.pl(12);.pt(8);
  &[aria-checked='true'], &:hover {.bgc(rgba(164, 163, 174, .2))}
}
[champ-select-item]:last-child {
  border-bottom-left-radius: 12px; border-bottom-right-radius: 12px;
}
</style>
