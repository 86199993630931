<template>
  <component :is="tag" prefer-champ-radio-group>
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'PreferChampRadioGroup',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    onValueChange(v) {
      this.$emit('input', v);
    }
  }
};
</script>